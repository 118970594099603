<template>
  <div class="deviceList" id="deviceList">
    <div class="searchView">
      <el-input
          @focus="deviceManagerSearch"
          size="medium"
          class="search"
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          v-model="query"
      >
      </el-input>


      <div class="other" @click="showOther = true">
        <!--        <svg-icon width="100%" height="100%" name="add"/>-->

        <el-popover
            popper-class="popoverView"
            placement="bottom"
            width="140"
            v-model="showOther"
        >
          <div class="otherItemView" v-for="(item) in otherList" @click="doSomeThing(item)">
            <svg-icon class="otherIcon" width="20px" height="20px" :name="item.icon"/>
            <div class="otherTitle">{{ item.title }}</div>
          </div>

          <svg-icon slot="reference" width="100%" height="100%" name="add"/>
        </el-popover>

      </div>
    </div>

    <div class="deviceView">
      <device-item @deviceDetail="deviceDetail" v-for="(item, index) in tableData" :key="index" :data-info="item"
      ></device-item>
    </div>

    <h-stack style="height: 40px;background-color: white">
      <el-pagination
          @current-change="currentChange"
          :page-size="pageSize"
          :pager-count="5"
          :current-page="page"
          layout="prev, pager, next"
          :total="totalCount"
      >
      </el-pagination>
    </h-stack>

    <image-preview @clickImage="deviceManagerSearch" @closePreview="closeImage" v-show="showImage" class="preview"
                   :image="image"
    ></image-preview>

  </div>
</template>

<script>
import DeviceItem from '@/views/deviceManager/deviceList/deviceItem'
import { Loading } from 'element-ui'
import HStack from '@/components/hStack/hStack'
import ImagePreview from '@/components/imagePreview/imagePreview'

export default {
  name: 'deviceList',
  components: { ImagePreview, HStack, DeviceItem },
  data() {
    return {
      showImage: false,
      image: '',
      loadingView: null,
      loading: false,
      page: 1,
      pageSize: 30,
      showOther: false,
      otherList: [
        { icon: 'icon-paizhao', title: '拍照', type: 'photo' },
        { icon: 'icon-pandian', title: '盘点', type: 'pandian' },
        { icon: 'icon-saomiao', title: '扫描条形码', type: 'scan' }
      ],
      query: '',
      tableData: [],
      totalCount: 0
    }
  },
  beforeDestroy() {
    this.removeLocalStorageEvent(this.updateImage)
  },
  mounted() {
    this.$nextTick(() => {
      if (typeof localStorage['corpInfo'] != 'undefined') {
        try {
          let corpInfo = JSON.parse(localStorage['corpInfo'])
          this.wxConfig(corpInfo)
        } catch (e) {

        }
      }
      this.loadTableData(true, true)
      this.addLocalStorageEvent(this.updateImage)
      this.updateImage()
    })
  },
  watch: {
    loading: {
      handler(value) {
        if (value) {
          this.startLoading()
        } else {
          if (this.loadingView == null) {
            return
          }
          this.loadingView.close()
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    closeImage: function() {
      this.image = ''
      this.showImage = false
      this.saveImage('')
    },
    updateImage: function() {
      this.getImage().then(res => {
        if (res.result == false) {
          this.showImage = false
          this.image = ''
          return
        }
        this.showImage = true
        this.image = res.image
      })
      this.loadTableData(false, false)
    },
    currentChange: function(index) {
      this.page = index
      this.loadTableData(false, true)
    },
    startLoading: function() {
      let dom = document.getElementById('deviceList')
      if (typeof dom == 'undefined' || dom == null) {
        return
      }
      this.loadingView = Loading.service({ target: dom })
    },
    reset: function() {
      this.page = 1
      this.pageSize = 30
    },
    loadTableData: function(reset, loading) {
      if (reset) {
        this.reset()
      }
      if (loading) {
        this.loading = true
      }

      let loadData = () => {
        let tenantId = ''

        let userInfo = this.$store.getters.userInfo
        if (this.$valueIsExist(userInfo, 'tenantId')) {
          tenantId = userInfo['tenantId']
        }

        let type = ''
        let qurey = this.$route.query
        if (this.$valueIsExist(qurey, 'type')) {
          type = qurey['type']
        }

        let getPost = {
          deleted: 0,
          deviceType: type,
          query: '',
          page: this.page,
          pageSize: this.pageSize,
          order: '',
          orderType: '',
          tenantId: tenantId,
          isTenantIdChild: 1,
          categoryId: ''
        }
        this.$api.assetModule.getDeviceList(getPost).then(res => {
          if (res.code == 200) {
            this.tableData = res.data
            this.totalCount = res.totalCount
          }
          this.loading = false

        }).catch(e => {
          this.loading = false
        })
      }

      loadData()

    },
    findCode: function(config) {

      let loadData = () => {
        let getPost = {
          deleted: 0,
          deviceType: '',
          query: '',
          page: 1,
          pageSize: 30,
          order: '',
          orderType: '',
          tenantId: '',
          isTenantIdChild: 1,
          categoryId: '',
          keyword:config.res
        }
        this.$api.assetModule.getDeviceList(getPost).then(res => {
          if (res.code == 200 && res.data.length > 0) {
            let item = res.data[0]
            this.deviceDetail(item);
          } else {
            this.$message.error(`该${config.type == 'barCode' ? '条形码' : '二维码'}没有录入`)
          }
          this.loading = false

        }).catch(e => {
          this.loading = false
        })
      }

      loadData();
    },
    doSomeThing: function(item) {
      if (item.type == 'scan') {
        this.scanQRCode().then(res => {
          if (res.result == false) {
            this.$message.error('请扫描二维码或者条形码')
            return
          }
          this.findCode(res)
        })
      } else if (item.type == 'photo') {
        this.chooseImage().then(res => {
          if (res.result == false) {
            this.showImage = false
            this.image = ''
            this.$message.error('请重新选择图片')
            this.saveImage('')
            return
          }
          this.showImage = true
          this.image = res.image
          this.saveImage(res.localID)
        })
      } else if (item.type == 'pandian') {
        /*
        * 扫描盘点 点击进入扫描盘点详情
        */
        this.$push('devicePandian', {})
      }
    },
    deviceManagerSearch: function() {
      this.$push('deviceManagerSearch', {})
    },
    deviceDetail: function(res) {
      if (this.$valueIsExist(res, 'id') == false) {
        return
      }
      let info = {
        id: res['id']
      }
      this.$push('deviceDetailManager', info)
    }
  }
}
</script>


<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceList {
  width: 100%;
  height: calc(100%);
  background-color: $background_color1;

  .deviceView {
    overflow: scroll;
    width: 100%;
    height: calc(100% - 90px);
  }

  .searchView {
    width: 100%;
    height: 50px;

    .search {
      margin-left: 10px;
      width: calc(100% - 60px);
      position: relative;
      top: 50%;
      transform: translate(0px, -50%);
      float: left;
    }

    .other {
      height: 28px;
      width: 28px;
      float: right;
      position: relative;
      top: 50%;
      right: 10px;
      transform: translate(0px, -50%);

    }

  }

}

::v-deep .search .el-input__inner {
  border-radius: 20px;
}

</style>


<style lang="scss">
@import "@styles/variables.scss";

.popoverView {
  background: $popover_dark !important;
  padding: 5px;

  .otherItemView {
    height: 28px;
    margin-bottom: 5px;

    .otherIcon {
      width: 28px;
      height: 28px;
      float: left;
      position: relative;
      top: 50%;
      transform: translate(0px, -50%)
    }

    .otherTitle {
      width: calc(100% - 28px);
      height: 28px;
      line-height: 28px;
      margin-left: 5px;
      border-bottom: 0.5px solid white;
      float: right;
      color: white;
    }

  }


}

.popoverView .popper__arrow::after {
  /* 注意：placement位置不同，下面的属性不同 */
  border-bottom-color: $popover_dark !important;
}

.preview {
  position: absolute;
  right: 10px;
  bottom: 10px;
}


</style>
